<script setup lang="ts">

const {t} = useI18n()
const storeConfig = useStoreConfig()
const {listingConfig} = storeToRefs(storeConfig)

const stockOutText = computed(() => {
  if (getLocaleString(unref(listingConfig)?.out_of_stock_text)) {
    return getLocaleString(unref(listingConfig)?.out_of_stock_text)
  } else {
    return t('listing.stock-out')
  }
})
</script>

<template>
  {{stockOutText}}
</template>